<template>
  <div class="">
    <my-header-view
      title="Catálogos"
      icon="mdi-folder-multiple-outline"
      text=""
      :bread-crumbs="breadCrums"
    />
    <v-row class="mt-4">
      <v-col cols="12" sm="4" md="3" v-for="menu in menus" :key="menu.name">
        <my-menu-card
          :title="menu.name"
          :description="menu.description"
          :icon="menu.icon"
          :url="menu.url"
          :img-url="menu.imgUrl ? menu.imgUrl : null"
          :backgroundColor="menu.backgroundColor"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import myHeaderView from "../ui/myHeaderView";
import myMenuCard from "../ui/myMenuCard";
export default {
  name: "Catalogs",
  components: {
    myHeaderView,
    myMenuCard,
  },
  data: () => ({
    breadCrums: [
      {
        text: "Inicio",
        disabled: false,
        url: "Home",
      },
      {
        text: "Configuración",
        disabled: false,
        url: "Configuracion",
      },
      {
        text: "Catálogos",
        disabled: true,
        url: "Catalogos",
      },
    ],
    menus: [
      {
        name: "Insumos",
        imgUrl: "/img/menus/catalogos/insumos.png",
        backgroundColor: "support",
        url: "ProductsMP",
        icon: "/img/icons/custom/disagro/fertilizantes.png",
      },
      {
        name: "Productos de Entrada",
        imgUrl: "/img/menus/catalogos/productos-entrada.png",
        backgroundColor: "primary",
        url: "ProductosEntrada",
        icon: "/img/icons/custom/disagro/hoja.png",
      },
      {
        name: "Material de Empaque",
        imgUrl: "/img/menus/catalogos/material-empaque.png",
        backgroundColor: "pla_c3",
        url: "MaterialEmpaque",
        icon: "/img/icons/custom/disagro/lista.png",
      },
      {
        name: "Maquinaria",
        imgUrl: "/img/menus/catalogos/maquinaria.png",
        backgroundColor: "support",
        url: "Maquinaria",
        icon: "/img/icons/custom/disagro/maquina.png",
      },
      {
        name: "Productos de Salida",
        imgUrl: "/img/menus/catalogos/productos-salida.png",
        backgroundColor: "primary",
        url: "ProductosSalida",
        icon: "/img/icons/custom/disagro/silo.png",
      },
      {
        name: "Subproductos de Salida",
        imgUrl: "/img/menus/catalogos/subproductos-salida.png",
        backgroundColor: "pla_c3",
        url: "SubproductosSalida",
        icon: "/img/icons/custom/disagro/documentos.png",
      },
    ],
  }),
};
</script>

<style scoped></style>
